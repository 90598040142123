// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "s_rj d_gv d_cs";
export var heroHeaderCenter = "s_gw d_gw d_cs d_dw";
export var heroHeaderRight = "s_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "s_rk d_gr d_cw";
export var heroParagraphCenter = "s_gs d_gs d_cw d_dw";
export var heroParagraphRight = "s_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "s_rl d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "s_rm d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "s_rn d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "s_rp d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "s_rq d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "s_rr w_rr";
export var heroExceptionNormal = "s_rs w_rs";
export var heroExceptionLarge = "s_rt w_rt";
export var Title1Small = "s_rv w_rv w_rH w_rJ";
export var Title1Normal = "s_rw w_rw w_rH w_rK";
export var Title1Large = "s_rx w_rx w_rH w_rL";
export var BodySmall = "s_ry w_ry w_rH w_r0";
export var BodyNormal = "s_rz w_rz w_rH w_r1";
export var BodyLarge = "s_rB w_rB w_rH w_r2";