// extracted by mini-css-extract-plugin
export var articleWrapper = "n_pT";
export var articleText = "n_pV d_dv d_cs";
export var header = "n_pW d_Z d_w";
export var headerImageWrapper = "n_pX d_bd d_0 d_8 d_7 d_4 d_9 d_bk d_bR";
export var headerGradient = "n_pY d_bd d_0 d_8 d_7 d_4 d_9";
export var headerGradientOverlay = "n_pZ d_bd d_0 d_8 d_7 d_4 d_9";
export var headerContentWrapper = "n_p0 d_bz d_bP d_bD d_bJ d_bl d_Z";
export var headerContentWrapperAlt = "n_p1 d_bz d_bP d_bD d_bJ d_bl d_Z";
export var contentWrapper = "n_mv d_w";
export var dateTag = "n_p2 d_cf d_cr d_dq d_w";
export var icon1 = "n_p3 d_cP";
export var icon2 = "n_p4 d_cP d_cJ";
export var tagBtn = "n_p5 d_b1 d_cD d_dp d_dB";
export var headerText = "n_p6 d_w d_cf d_cs d_bW";
export var center = "n_p7 d_dw";
export var videoIframeStyle = "n_p8 d_d5 d_w d_H d_by d_b1 d_R";
export var articleImageWrapper = "n_p9 d_cs d_Z";
export var articleImageWrapperIcon = "n_qb d_cs d_Z";
export var articleRow = "n_qc d_bD";
export var quoteWrapper = "n_gQ d_bC d_bP d_cs";
export var quoteBar = "n_qd d_H";
export var quoteText = "n_qf";
export var authorBox = "n_qg d_w";
export var authorRow = "n_qh d_bD d_bW d_cy";
export var separator = "n_qj d_w";
export var line = "n_fk d_w d_fk d_cY d_cy";
export var authorImage = "n_ln d_b7 d_Z d_w d_ct";
export var authorText = "n_qk d_ch d_ct";
export var masonryImageWrapper = "n_ql";
export var bottomSeparator = "n_qm d_w d_cy";
export var linksWrapper = "n_qn d_ch d_dv";
export var comments = "n_qp d_bD d_cy";
export var sharing = "n_qq d_bz d_bD d_bJ";
export var shareText = "n_qr d_w d_dw";
export var icon = "n_qs";
export var customRow = "n_pD d_bD d_Z";
export var text = "n_qt";
export var SubtitleSmall = "n_qv";
export var SubtitleNormal = "n_qw";
export var SubtitleLarge = "n_qx";